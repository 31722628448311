import React, { useState } from 'react';
import './Enroll.css';
import { FaTwitter } from 'react-icons/fa'; // Ensure you have 'react-icons' package installed


function Enroll({ onClose }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailError, setEmailError] = useState("");
    const [isSignUpComplete, setIsSignUpComplete] = useState(false);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    const handleTweet = () => {
        // Define your tweet message here
        const tweetMessage = encodeURIComponent("If you go to https://betlikebiff.com , you'll find the best sports picks. Also a chance to win $25!  #SportsBetting #WinningTips #gamblingtwitter #parlay #slips");
      
        // Open Twitter Web Intent in a new tab
        window.open(`https://twitter.com/intent/tweet?text=${tweetMessage}`, '_blank');
      };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    
        if (!validateEmail(newEmail)) {
            setEmailError("Please enter a valid email.");
        } else {
            setEmailError("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            firstName,
            lastName,
            email,
            phone,
        };

        fetch('https://api.betlikebiff.com/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setIsSignUpComplete(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="enroll">
            <div className="enroll-container">
                {isSignUpComplete ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <p className='account-text'>Your account will be ready soon. Please monitor your inbox.</p>
                        <button onClick={handleTweet} className="tweet-button">
                            <FaTwitter /> Tweet about it!
                        </button>
                    </div>
                ) : (
                    <>
                        <h1 className="enroll-title">Join Beta</h1>
                        <form className="enroll-form" onSubmit={handleSubmit}>
                            <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="enroll-input" required />
                            <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="enroll-input" required />
                            <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} className="enroll-input" required />
                            {emailError && <div className="error">{emailError}</div>}
                            <input type="tel" placeholder="Phone (optional)" value={phone} onChange={(e) => setPhone(e.target.value)} className="enroll-input" />
                            <button type="submit" className="enroll-button">Sign Up</button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default Enroll;