import React, { useState, useEffect } from 'react'
import './Card.css'

function Example() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const text = 'After reviewing the data from the following sportsbooks, I have assigned the probablility rating of 50.86% for Jimmy Butler to score over 22.5 points.';
    let index = 0;

    const interval = setInterval(() => {
      setMessage(prevMessage => prevMessage + text[index]);
      index++;
    
      if (index === text.length - 1) {
        clearInterval(interval);
      }
    }, 100);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='card'>
        <h1 className='card-text-title'>
          <img src="https://pbs.twimg.com/profile_images/1769461339422875648/xktWjLPF_400x400.jpg" alt="Biff AI Icon" />
          Biff.AI
        </h1>
        <div className='chatbox'>
          <div className='chat-message ai-typing'>{message}</div>
        </div>
    </div>
  )
}

export default Example