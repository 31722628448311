import React, { useState } from 'react';
import './Header.css';
import Modal from '../components/Modal'; // import your Modal component

function Header() {
  const [showModal, setShowModal] = useState(false);

  const handleSignInClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <header className="header">
      <a href="/" className="site-logo">
        <h1>BetLikeBiff.com</h1>
      </a>
      <div className="discord-link-container">
        <a href="https://discord.gg/beb4hTXVfZ" target="_blank" rel="noopener noreferrer" className="discord-link">
          <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png" alt="Discord Icon" width={50}/>
        </a>
      </div>
      <button className="sign-in-button" onClick={handleSignInClick}>Sign In</button>
      {showModal && <Modal onClose={handleCloseModal} />}
    </header>
  );
}

export default Header;