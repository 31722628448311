import React from 'react';
import '../pages/Login.css';
import Card from '../components/Card';
import Example from '../components/Example';
import { useNavigate } from "react-router-dom";



function Login() {


  const navigate = useNavigate();
  const handleSeeDetailsClick = () => {
      navigate('/signup');
  };

const playerObject = {
name: 'Jimmy Butler',
};

  
    return (
        <div className="App">
          <header className="App-header">
         
            <h2>Sports Betting Tools Enhanced by Artifical Intelligence</h2>
    
           <div className='card-center' style={{width: '100vh'}}> {/*lets have these items appear a few seconds after landing so we can see the background */}
            <Card name = {playerObject.name} imageUrl='https://cdn.nba.com/headshots/nba/latest/1040x760/202710.png'/>
            <Example />
           </div>

            <button className='call-to-action' onClick={handleSeeDetailsClick}>Join the Beta!</button>
              
          </header>
        </div>
      );
}

export default Login