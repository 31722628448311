import React, { useState } from 'react';
import './Modal.css';


function Modal({ onClose }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

const [emailError, setEmailError] = useState("");

  const handleSwitch = () => {
    setIsSignUp(!isSignUp);
  };
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  
    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      phone,
    };

    fetch('https://api.betlikebiff.com/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        onClose(); // Assuming you might want to close the modal on successful submission
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">X</button>
        {isSignUp ? (

          <>
            <h2>Sign Up</h2>
            <form className="sign-up-form" onSubmit={handleSubmit}>
              <label>
                First Name:
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="sign-up-input"
                  required


                />
              </label>
              <label>
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="sign-up-input"
                  required

                />
              </label>
              <label>
  Email:
  <input
    type="email"
    name="email"
    placeholder="Email"
    value={email}
    onChange={handleEmailChange}
    className="sign-up-input"
  />
  {emailError && <div className="error">{emailError}</div>}
</label>
              <label>
                Phone (optional):
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="sign-up-input"
                />
              </label>
              <button type="submit" className="sign-up-button">Sign Up</button>
            </form>
          </>
) : (
          <>
            <h2>Sign In</h2>
            <form className="sign-up-form">
              <label>
                Username:
                <input type="text" name="username" className="sign-up-input" />
              </label>
              <label>
                Password:
                <input type="password" name="password" className="sign-up-input" />
              </label>
              <input type="submit" value="Sign In" className="sign-up-button" />
            </form>
            <p>or</p>
            <button type="button" onClick={handleSwitch} className="sign-up-button">Sign Up</button>
          </>
        )}

      </div>
    </div>
  );
}

export default Modal;
