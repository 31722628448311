import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import Header from './components/Header';
import Enroll from './pages/Enroll';

function App() {
 

  return (
    <Router>
      <Header />
    <Routes>
      <Route path="/" element={<Login  />} />
      <Route path="/home" element={<Home  />} />
      <Route path="/signup" element={<Enroll  />} />
    </Routes>
  </Router>
  );
}

export default App;