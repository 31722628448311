import React from 'react'
import './Card.css';
import { useNavigate } from "react-router-dom";



function Card({name, imageUrl}) {
   
    const navigate = useNavigate();
    const handleSeeDetailsClick = () => {
        navigate('/signup');
    };
    
    return (
        <div className='card'>
                <h1 className='card-name'>{name}</h1>
                <img src={imageUrl} alt={name} className='card-image' />
                <p className='card-text'>AI Probability Rating</p>
                <p className='card-text-ev'>{50.86}%</p>
                <button className="see-details-button" onClick={handleSeeDetailsClick}>See Details</button>
        </div>
    )
}

export default Card